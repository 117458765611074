@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

input,
textarea,
select {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/*@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}*/

body {
  color: rgb(var(--foreground-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.gradient {
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.buy-sim-card-effect {
  &:after {
    content: '';
    width: 24px;
    height: 24px;
    background: #fff;
    border: 3px solid #ffffff;
    transform: rotate(223deg);
    position: absolute;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    top: auto;
    right: -14px;
    bottom: -11px;
  }
}

.flag-sim-card-effect {
  /*
  border: 1px solid #999;
  */
  box-sizing: content-box;

  &:after {
    content: '';
    width: 9px;
    height: 9px;
    background: #fff;
    /*border: 1px solid #999;*/
    transform: rotate(225deg);
    position: absolute;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    top: auto;
    right: -5px; /*2px*/
    bottom: -5px;
  }
}

.bordered.flag-sim-card-effect {
  border: 1px solid #999;

  &:after {
    border: 1px solid #999;
  }

}

.horizontall-scroll {
  width: 200px;
  overflow-x: auto;
  white-space: nowrap;
}

.spinner_6kVp {
  transform-origin: center;
  animation: spinner_irSm 0.75s infinite linear;
}
@keyframes spinner_irSm {
  100% {
    transform: rotate(360deg);
  }
}
